<template>
  <div class="account-email-container">
    <div class="account-email-content">
      <div class="account-email-back">
        <router-link to="/account_email_update">
          <!-- @click.native="installQuit()" -->
          <chevron :iconDirection="'left'" />
        </router-link>
      </div>

      <!-- <div class="image-container">
        <img src="images/car-account-confirmation.png" alt="">
      </div> -->

      <div class="account-email-title">
        {{ $t('email_account_title') }}
      </div>
      <p class="account-email-subtitle">
        {{ $t('email_account_subtitle', { userEmail: getUserName }) }}
      </p>
      <p class="account-email-description">
        {{ $t('email_account_text') }}
        <router-link to="/account_email_update">
          {{ $t('email_account_change_link') }}
        </router-link>
      </p>

    </div>
    <div class="account-email-footer">
      <div class="account-email-buttonsgroup">
        <b-button @click="exit()" class="b-btn-checkout account-email-button-finish" block variant="primary">
          {{ $t('email_account_understand') }}
        </b-button>
        <b-button v-if="!isEmailSend" @click="sendEmail()"
          class="b-btn-checkout account-email-button-finish button-transparent" block variant="primary">
          {{ $t('email_account_resend') }}
        </b-button>
        <b-button v-else class="b-btn-checkout account-email-button-finish button-transparent" block
          variant="primary">
          {{ $t('email_account_isresend') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Chevron from '../svg/chevron.vue';

export default {
  components: { Chevron },
  name: 'AccountEmail',

  data() {
    return {
      isEmailSend: false,
    }
  },

  methods: {
    ...mapActions("users", ["sendEmailConfirmation", "getUserData", "fetchUserData"]),
    ...mapActions('users', ['setToken']),

    exit() {
      window.location.href = '/account/';
    },

    async sendEmail() {
      let sendEmail_param = { email_confirmation_send: true };
      let response = await this.sendEmailConfirmation(sendEmail_param);
      if (response.status == 200) {
        
        this.isEmailSend = true
      }
    }
  },

  computed: {
    ...mapGetters("users", ["getUserName"]),
  },

  async mounted() {
    let parameters = this.$route.query;
    if (parameters['token']) {
      let token = parameters['token'];
      this.setToken(token);
    }
    this.fetchUserData();
    window.zE('webWidget', 'hide');
  },
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.account-email-container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .account-email-content {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1.5em;
  }

  .button-transparent {
    background-color: transparent;
    border-color: transparent;
    color: grey;

    &:hover {
      background-color: transparent;
      border-color: transparent;
      color: grey;
    }

    &:active {
      background-color: transparent;
      border-color: transparent;
      box-shadow: 0 0 0 0;
      color: grey;
    }

    &:focus {
      background-color: transparent;
      border-color: transparent;
      box-shadow: 0 0 0 0;
      color: grey;
    }

    &:active:focus {
      background-color: transparent;
      border-color: transparent;
      box-shadow: 0 0 0 0;
      color: grey;
    }

    &:focus-visible {
      background-color: transparent;
      border-color: transparent;
      outline: none;
      color: grey;
    }
  }


  .account-email-footer {
    display: flex;
    gap: 1em;
    padding: 1.5em;
    width: 100%;
  }

  .account-email-title {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
  }

  .account-email-subtitle {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }

  .image-container {
    flex: 1;
    display: flex;
    position: relative;
    justify-content: center;
  }

  .account-email {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    &-password {
      display: block !important;

      &-eyes {
        position: absolute;
        right: 2.5em;
        margin-top: 1em;
      }
    }

    &-image {
      height: 100%;
      position: absolute;
    }

    &-description {
      font-family: "Open Sans", sans-serif;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      color: #555b62;
    }

    &-buttonsgroup {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      width: 100%;

      .button-expand {
        padding: 0em 1em !important;

        &-header {
          padding: 1em 0em;
        }
      }

      .is-invalid {
        color: #f95658 !important;
      }

      .account-email-button.is-invalid {
        border: 2px solid #f95658 !important;
        font-weight: 500 !important;
        background: transparent !important;
      }

      .account-email-button {
        background: #ffffff;
        border: 2px solid #eaeaea;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 1em;
        width: 100%;

        &:focus {
          box-shadow: none;
        }

        &:focus-visible {
          outline: none;
        }

        &-finish {
          font-size: 18px;
          padding: 1em;
        }

        &-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: left;
        }

        &-text {
          width: 90%;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #555b62;
        }
      }

      .btn-secondary:not(:disabled):not(.disabled).is-invalid {
        border: 1px solid #f95658 !important;
        background: #feeeee;
        box-shadow: 0px 0px 0px 1px #feeeee !important;
      }

      .btn-secondary:not(:disabled):not(.disabled).active {
        border: 1px solid #0090f8 !important;
        box-shadow: 0px 0px 0px 1px #0090f8 !important;

        .account-email-button-text {
          color: black;
        }
      }

      .account-expand-summary {
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        .expand-summary-item {
          font-family: "Open Sans";
          font-weight: 400;
          font-size: 14px;
          color: #555b62;
          text-align: left;

          &:last-child {
            padding-bottom: 1em;
          }
        }

        .expand-summary-item.is-invalid {
          color: #f95658 !important;
          font-weight: 500 !important;
          border: none !important;
          background: transparent !important;
        }
      }

      .button-expand-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .button-expand {
        &-title {
          color: #050328;
          font-family: "Open Sans", sans-serif;
          font-weight: 600;
          font-size: 14px;
          text-align: left;
        }

        &-body {
          display: flex;
          flex-direction: column;
          gap: 0.5em;
          border-top: #eaeaea 2px solid;
          padding: 1em 0em;
        }

        &-inputgroup {
          display: flex;
          flex-direction: column;
        }

        &-label {
          font-family: "Open Sans";
          font-weight: 400;
          font-size: 14px;
          color: #555b62;
          text-align: left;
        }

        &-input {
          background: #ffffff;
          border: 1px solid #8b949f;
          box-sizing: border-box;
          border-radius: 8px;
          font-family: "Open Sans";
          font-size: 14px;
          padding: 0.75em;
          margin-top: 0.5em;

          &:focus-visible {
            border: 1px solid #0090f8 !important;
            box-shadow: 0px 0px 0px 1px #0090f8 !important;
            outline: none;
            background: white;
          }
        }

        .is-invalid {
          border: 1px solid #f95658 !important;
          background: #feeeee;

          &:focus-visible {
            border: 1px solid #f95658 !important;
            box-shadow: 0px 0px 0px 1px #feeeee !important;
          }
        }
      }
    }
  }
}
</style>