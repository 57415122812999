import { render, staticRenderFns } from "./AccountEmail.vue?vue&type=template&id=46aa477b&scoped=true&"
import script from "./AccountEmail.vue?vue&type=script&lang=js&"
export * from "./AccountEmail.vue?vue&type=script&lang=js&"
import style0 from "./AccountEmail.vue?vue&type=style&index=0&id=46aa477b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46aa477b",
  null
  
)

export default component.exports